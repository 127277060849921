import {
  lowerBreakpointDesktopPx,
  lowerBreakpointTabletPx,
  lowerBreakpointWqhdPx,
} from "@/utils/util";
import { ImageWithMarkersContentElementProps } from "./imageWithMarkersContentElement";

interface ImageWithMarkersContentElementStyleScopedProps
  extends ImageWithMarkersContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 */
export default function ImageWithMarkersContentElementStyleScoped(
  props: ImageWithMarkersContentElementStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
        height: ${props.content.cfgImageHeight || 100}px;
        /* The text-box in the modal is styled separately (.imageWithMarkersCE-text-box). */
        .text-box {
          max-height: ${props.content.cfgImageHeight}px;
          max-width: ${props.content.cfgImageWidth}px;
          .richtext-view,
          .richtext-editor {
            margin-right: ${props.ceSettings?.textMargin.right ?? 0}px;
            margin-left: ${props.ceSettings?.textMargin.left ?? 0}px;
          }
        }

        .marker {
          height: ${props.ceSettings?.markerSize.mobile}px;
          width: ${props.ceSettings?.markerSize.mobile}px;

          @media (min-width: ${lowerBreakpointTabletPx}) {
            height: ${props.ceSettings?.markerSize.tablet}px;
            width: ${props.ceSettings?.markerSize.tablet}px;
          }

          @media (min-width: ${lowerBreakpointDesktopPx}) {
            height: ${props.ceSettings?.markerSize.desktop}px;
            width: ${props.ceSettings?.markerSize.desktop}px;
          }

          @media (min-width: ${lowerBreakpointWqhdPx}) {
            height: ${props.ceSettings?.markerSize.wqhd}px;
            width: ${props.ceSettings?.markerSize.wqhd}px;
          }
        }
      }

      :global(.${props.scopedSelector}-modal.imageWithMarkersCE-text-box) {
        .richtext-view,
        .richtext-editor {
          margin-right: ${props.ceSettings?.textMargin.right ?? 0}px;
          margin-left: ${props.ceSettings?.textMargin.left ?? 0}px;
          min-height: 25px;
        }
      }

      :global(.${props.scopedSelector}-modal.imageWithMarkersCE-text-box) {
        .richtext-view,
        .richtext-editor {
          margin-right: ${props.ceSettings?.textMargin.right ?? 0}px;
          margin-left: ${props.ceSettings?.textMargin.left ?? 0}px;
        }
      }
    `}</style>
  );
}
