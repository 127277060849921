import CmsContentImage from "@/components/cms/cmsContent/cmsContentImage";
import CmsImage, {
  ImageResponsiveSettings,
} from "@/components/cms/cmsImage/cmsImage";
import OptimizeStrapiImage, {
  OptimizeStrapiImageWidthLimits,
} from "@/components/cms/cmsImage/optimizeStrapiImage";
import { useAppSelector } from "@/store/store";
import { StrapiUploadFile } from "@/types/strapi";

export interface PbContentImageProps {
  img?: StrapiUploadFile;
  cmsPosition?: number;
  cmsFieldImage?: string;
  height: string | number;
  width: string | number;
  placeholderWidth?: number;
  placeholderHeight?: number;
  disableEditView?: boolean;
  className?: string;
  isMobile: boolean;
  objectFit?: string;
  objectPosition?: string;
  responsive?: ImageResponsiveSettings;
  skipOptimization?: boolean;
  maxWidth?: string;
  widthLimitSettings?: OptimizeStrapiImageWidthLimits | number | undefined;
  imageRef?: any;
  maxHeight?: string;
}

export default function PbContentImage(props: PbContentImageProps) {
  const editView = useAppSelector((state) => state.cmsGeneral.editView);

  return (
    <div
      style={{ maxWidth: props.maxWidth, maxHeight: props.maxHeight }}
      className={props.className}
    >
      {editView && !props.disableEditView ? (
        <CmsContentImage {...props} />
      ) : (
        <div className="pb-image">
          {props.img && (
            <OptimizeStrapiImage
              image={props.img}
              isMobile={props.isMobile}
              skipOptimization={props.skipOptimization}
              widthLimitSettings={props.widthLimitSettings}
            >
              <CmsImage
                imageRef={props.imageRef}
                image={props.img}
                height={props.height}
                width={props.width}
                objectFit={props.objectFit}
                objectPosition={props.objectPosition}
                responsive={props.responsive}
              ></CmsImage>
            </OptimizeStrapiImage>
          )}
        </div>
      )}
    </div>
  );
}
