import CmsGeneralConfigFrame from "@/components/cms/cmsGeneralConfigFrame/cmsGeneralConfigFrame";
import CmsTooltip from "@/components/cms/cmsTooltip/cmsTooltip";
import PbContentImage from "@/components/content/pbContentImage/pbContentImage";
import PbContentRichText from "@/components/content/pbContentRichText/pbContentRichText";
import PbGenericModal from "@/components/genericModal/pbGenericModal";
import PbButton from "@/components/input/pbButton/pbButton";
import { PbIcon } from "@/components/pbIcon/PbIcon";
import AnimationWrapper from "@/components/util/animationWrapper/animationWrapper";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useConfirmModal from "@/hooks/useConfirmModal";
import useImageWithMarkersSettings from "@/hooks/useImageWithMarkersSettings";
import useScopedClassName from "@/hooks/useScopedClassName";
import useStrIcon from "@/hooks/useStrIcon";
import useWindowSize from "@/hooks/useWindowSize";
import {
  ceSettingById,
  getNestedCeSettingsData,
  valueFromStoreSetting,
} from "@/services/ceSettings/ceSettingsService";
import {
  removeFromArrayAction,
  updateAttributeAction,
} from "@/store/slices/cmsEdit/cmsEditSlice";
import { cmsAddNestedContentElementThunk } from "@/store/slices/cmsEdit/cmsPageThunks";
import { showNestedConfigModal } from "@/store/slices/cmsGeneral/cmsGeneralSlice";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { ContentElementImageWithMarkersStoreSetting } from "@/types/ceSettings/ceSettings";
import {
  CEImageWithMarkers,
  CEImageWithMarkersItem,
} from "@/types/content-elements";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import React, { createRef, SyntheticEvent, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Draggable from "react-draggable";
import { Resizable, ResizeCallbackData } from "react-resizable";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import ImageWithMarkersContentElementStyleGlobal from "./imageWithMarkersContentElementStyleGlobal";
import ImageWithMarkersContentElementStyleScoped from "./imageWithMarkersContentElementStyleScoped";

export interface ImageWithMarkersContentElementProps {
  content: CEImageWithMarkers;
  position: number;
  ceSettings?: ContentElementImageWithMarkersStoreSetting;
  isLastElement: boolean;
  isMobile: boolean;
  isFirstElement: boolean;
}

export const IMAGE_WITH_MARKERS_CE_NAME = "imageWithMarkersCE";

export default React.memo(function ImageWithMarkersContentElement(
  props: ImageWithMarkersContentElementProps
) {
  const { t: tCms } = useTranslation("cms");
  const editView = useAppSelector((state) => state.cmsGeneral.editView);
  const IMAGE_WITH_MARKERS_SCOPED_CLASS = useScopedClassName(
    IMAGE_WITH_MARKERS_CE_NAME,
    props.content,
    props.position
  );
  const { getIcon } = useStrIcon();
  const dispatch = useAppDispatch();
  useWindowSize();

  const contentRef = useRef<HTMLDivElement>(null);
  const textItemsRef = useRef<any>([]);
  const markersRef = useRef<any>([]);
  const imageRef = useRef<HTMLImageElement>(null);

  const [positionEditMode, setPositionEditMode] = useState<boolean>(true);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedMarker, setSelectedMarker] = useState<number>(-1);
  const [dialogContent, setDialogContent] = useState<
    CEImageWithMarkersItem | undefined
  >();
  // text boxes that are temporarly pushed inside the users view but are positioned outside of it
  const [pushedTextboxIds, setPushedTextboxIds] = useState<Array<number>>([]);

  const { overlayItemBackgroundColor, overlayItemMarkerColor } =
    useImageWithMarkersSettings(props.ceSettings);

  const { richTextClassName } = useCesStrRichText(
    props.ceSettings?.richtext,
    undefined
  );

  const nestedElementAttributes = useAppSelector((state) => {
    return state.cmsEdit.data?.availableElements?.find(
      (element: any) => props.content.__component === element.uid
    )?.nestedElement;
  });

  const cfgShowMarkersOnAllSizes = props.content.cfgShowMarkersOnAllSizes
    ? true
    : false;

  const { showConfirmModal } = useConfirmModal(
    () => {
      dispatch(
        removeFromArrayAction({
          attributePath: `draftPage.content[${props.position}].content`,
          position: selectedMarker,
        })
      );
    },
    () => {
      setSelectedMarker(-1);
    }
  );

  const { showConfirmModal: showResetPositionConfirmModal } = useConfirmModal(
    () => {
      if (props.content.content) {
        props.content.content.forEach((element: any, index: number) => {
          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.content[${props.position}].content[${index}].markerPosition`,
              value: { x: 1, y: 1 },
            })
          );
          dispatch(
            updateAttributeAction({
              attributePath: `draftPage.content[${props.position}].content[${index}].position`,
              value: { x: 1, y: 1 },
            })
          );
        });
      }
    }
  );

  const handleOnMarkerDelete = (index: number) => {
    setSelectedMarker(index);
    showConfirmModal({
      title: tCms("nestedContent-deleteImgWithMarkersMarker"),
      content: tCms("nestedContent-deleteImgWithMarkersMarkerLong"),
      acceptBtnText: tCms("delete"),
      denyBtnText: tCms("cancel"),
      xIsDeny: true,
      icon: "circle-exclamation-light",
    });
  };

  const arrLength = props.content.content.length;
  if (textItemsRef.current.length !== arrLength) {
    textItemsRef.current = Array(arrLength)
      .fill(undefined)
      .map((_, i) => textItemsRef.current[i] || createRef());
  }
  if (markersRef.current.length !== arrLength) {
    markersRef.current = Array(arrLength)
      .fill(undefined)
      .map((_, i) => markersRef.current[i] || createRef());
  }

  const toggleDialog = (element?: CEImageWithMarkersItem) => {
    setDialogOpen((prevState: boolean) => !prevState);
    if (element) {
      setDialogContent(element);
    }
  };

  // onRepositionStop triggers when the user lets go of a textbox after dragging it
  const onRepositionStop = (
    position: { x: number; y: number },
    index: number
  ) => {
    const { x, y } = position;
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.position}].content[${index}].position`,
        value: {
          x: x,
          y: y,
        },
      })
    );
  };

  // triggers when the user lets go of the marker after dragging it
  const updateMarkerPosInRedux = (
    position: { x: number; y: number },
    index: number
  ) => {
    dispatch(
      updateAttributeAction({
        attributePath: `draftPage.content[${props.position}].content[${index}].markerPosition`,
        value: {
          x: position.x,
          y: position.y,
        },
      })
    );
  };

  /**
   * Changes a text box visibility
   * - Text box should be visible except:
   * - If text box is outside image and gets pushed inside it -> invisible.
   * - If text box is inside image and gets pushed outside of it -> invisible.
   */
  const updateTextboxVisibility = (
    item: CEImageWithMarkersItem,
    textboxBoundaries: DOMRect,
    containerBoundaries: DOMRect,
    textboxIndex: number
  ) => {
    const imgWidth = Math.round(props.content.cfgImageWidth);

    const pushTextBoxToLeft =
      containerBoundaries.width / 2 + item.position.x + item.width >
      containerBoundaries.right;

    const pushTextBoxToRight =
      (containerBoundaries.width / 2) * -1 > item.position.x;

    const isPositionedOutsideImage =
      item.position.x > imgWidth / 2 || (imgWidth / 2) * -1 > item.position.x;

    const isRightSideInsideImage =
      textboxBoundaries.right >= containerBoundaries.right / 2 - imgWidth / 2 &&
      textboxBoundaries.right <= containerBoundaries.right / 2 + imgWidth / 2;

    const isLeftSideInsideImage =
      textboxBoundaries.left >= containerBoundaries.right / 2 - imgWidth / 2 &&
      textboxBoundaries.left <= containerBoundaries.right / 2 + imgWidth / 2;

    const isPushedInsideImage = isRightSideInsideImage || isLeftSideInsideImage;

    textItemsRef.current[textboxIndex].current.style.display = "block";

    if (isPositionedOutsideImage && isPushedInsideImage) {
      textItemsRef.current[textboxIndex].current.style.display = "none";
    }

    if (
      !isPositionedOutsideImage &&
      (pushTextBoxToRight || pushTextBoxToLeft)
    ) {
      textItemsRef.current[textboxIndex].current.style.display = "none";
    }
  };

  /**
   * Pushes the texbox if the left or right side of the window is touching it
   * @returns a new textbox width while it is being pushed
   */
  const pushTextbox = (
    containerBoundaries: DOMRect,
    textboxIndex: number,
    width: number,
    item: CEImageWithMarkersItem
  ) => {
    const dragButtonWidth = editView ? 50 : 0;
    let tempPositionX = 0;
    let textboxWidth = width;

    const pushTextBoxToLeft =
      containerBoundaries.width / 2 + item.position.x + item.width >
      containerBoundaries.right;

    const pushTextBoxToRight =
      (containerBoundaries.width / 2) * -1 > item.position.x - dragButtonWidth;

    if (pushTextBoxToRight) {
      tempPositionX = (containerBoundaries.width / 2) * -1 + dragButtonWidth;

      textItemsRef.current[
        textboxIndex
      ].current.style.transform = `translate(${tempPositionX}px, ${item.position.y}px)`;
    }

    if (pushTextBoxToLeft) {
      tempPositionX = containerBoundaries.width / 2 - item.width;

      textItemsRef.current[
        textboxIndex
      ].current.style.transform = `translate(${tempPositionX}px, ${item.position.y}px)`;

      textboxWidth = item.width;
    }

    if (pushTextBoxToLeft || pushTextBoxToRight) {
      if (!pushedTextboxIds.includes(textboxIndex)) {
        setPushedTextboxIds((prevState) => [...prevState, textboxIndex]);
      }
    }

    if (!pushTextBoxToLeft && !pushTextBoxToRight) {
      if (pushedTextboxIds.includes(textboxIndex)) {
        const newPushedTextboxIds = pushedTextboxIds.filter(
          (id) => id !== textboxIndex
        );
        setPushedTextboxIds(newPushedTextboxIds);
      }
    }

    return { tempWidth: textboxWidth, tempPositionX };
  };

  const correctReduxWidthHeight = (
    width: number,
    height: number,
    reduxWidth: number,
    reduxHeight: number,
    index: number
  ) => {
    if (reduxWidth !== width) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].width`,
          value: width,
        })
      );
    }

    if (reduxHeight !== height) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].height`,
          value: height,
        })
      );
    }
  };

  const correctReduxMarkerPos = (
    posX: number,
    posY: number,
    reduxPos: { x: number; y: number },
    index: number
  ) => {
    if (reduxPos.x !== posX) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].markerPosition.x`,
          value: posX,
        })
      );
    }

    if (reduxPos.y !== posY) {
      dispatch(
        updateAttributeAction({
          attributePath: `draftPage.content[${props.position}].content[${index}].markerPosition.y`,
          value: posY,
        })
      );
    }
  };

  /**
   * Calculates allowed textbox size, position and toggles visibility
   */
  const calcTextboxBoundaries = (
    index: number,
    item: CEImageWithMarkersItem
  ) => {
    const textboxBoundaries: DOMRect =
      textItemsRef.current[index]?.current?.getBoundingClientRect();
    const containerBoundaries: DOMRect =
      contentRef.current?.getBoundingClientRect()!;

    if (textboxBoundaries && containerBoundaries && editView) {
      updateTextboxVisibility(
        item,
        textboxBoundaries,
        containerBoundaries,
        index
      );

      // max width
      const currentTextBoxWidth =
        textboxBoundaries.right - textboxBoundaries.left;
      const distanceToRight =
        containerBoundaries.right - textboxBoundaries.right;
      const maxWidth = Math.floor(currentTextBoxWidth + distanceToRight);

      // max height
      const currentTextboxHeight =
        textboxBoundaries.bottom - textboxBoundaries.top;
      const distanceToBottom =
        containerBoundaries.bottom - textboxBoundaries.bottom;
      const maxHeight = Math.floor(currentTextboxHeight + distanceToBottom);

      const height = item.height <= maxHeight ? item.height : maxHeight;
      let width = item.width <= maxWidth ? item.width : maxWidth;
      const { tempPositionX, tempWidth } = pushTextbox(
        containerBoundaries,
        index,
        width,
        item
      );
      width = tempWidth;

      correctReduxWidthHeight(width, height, item.width, item.height, index);

      return { maxWidth, maxHeight, width, height, tempPositionX };
    } else {
      return {
        maxWidth: item.width,
        maxHeight: item.height,
        width: item.width,
        height: item.height,
        tempPositionX: 0,
      };
    }
  };

  /**
   * Calculates the marker position
   */
  const calcMarkerPosition = (item: CEImageWithMarkersItem, index: number) => {
    const imgWidth = Math.round(props.content.cfgImageWidth);
    const imgHeight = Math.round(props.content.cfgImageHeight);
    const imgBoundaries = imageRef.current?.getBoundingClientRect();
    let markerPos = { ...item.markerPosition };

    const isInsideImageX =
      markerPos.x > (imgWidth / 2) * -1 && markerPos.x < imgWidth / 2;
    const isInsideImageY =
      markerPos.y < imgHeight / 2 && markerPos.y > (imgHeight / 2) * -1;
    const isOutsideRight = !isInsideImageX && Math.sign(markerPos.x) === 1;
    const isOutsideLeft = !isInsideImageX && Math.sign(markerPos.x) === -1;
    const isOutsideTop = !isInsideImageY && Math.sign(markerPos.y) === -1;
    const isOutsideBottom = !isInsideImageY && Math.sign(markerPos.y) === 1;

    if (isOutsideRight) {
      markerPos.x = imgWidth / 2;
    }

    if (isOutsideLeft) {
      markerPos.x = (imgWidth / 2) * -1;
    }

    if (isOutsideTop) {
      markerPos.y = (imgHeight / 2) * -1;
    }

    if (isOutsideBottom) {
      markerPos.y = imgHeight / 2;
    }

    if (imgBoundaries) {
      const maxImgWidth = Math.round(props.content.cfgImageWidth);
      const imgIsResized = imgBoundaries.width < maxImgWidth;
      if (imgIsResized) {
        const factor = imgBoundaries.width / maxImgWidth;
        markerPos.x = Math.round(markerPos.x * factor);
        markerPos.y = Math.round(markerPos.y * factor);
      } else {
        correctReduxMarkerPos(
          markerPos.x,
          markerPos.y,
          item.markerPosition,
          index
        );
      }
    }

    return markerPos;
  };

  const makeTextboxVisible = (index: number) => {
    const textbox: HTMLDivElement = textItemsRef.current[index]?.current;
    if (textbox) {
      const richtext = textbox.children[1] as HTMLDivElement;
      const arrow = textbox.children[2] as HTMLDivElement;
      richtext.style.opacity = "1";
      arrow.style.opacity = "1";
    }
  };

  const toggleTextboxesVisibility = () => {
    textItemsRef.current.forEach((textboxRef: any) => {
      const textbox = textboxRef.current.children[1] as HTMLDivElement;
      const arrow = textboxRef.current.children[2] as HTMLDivElement;
      const isVisible = textbox.style.opacity !== "0";

      if (isVisible) {
        textbox.style.opacity = "0";
        arrow.style.opacity = "0";
      } else {
        textbox.style.opacity = "1";
        arrow.style.opacity = "1";
      }
    });
  };

  return (
    <>
      <ContentElementWrapper
        name={IMAGE_WITH_MARKERS_CE_NAME}
        scopedClassName={IMAGE_WITH_MARKERS_SCOPED_CLASS}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div
          className={clsx(
            IMAGE_WITH_MARKERS_CE_NAME,
            IMAGE_WITH_MARKERS_SCOPED_CLASS
          )}
        >
          <Row
            id={
              props.content.cfgAnchorName
                ? `anchor-${props.content.cfgAnchorName}`
                : ""
            }
            className="g-0"
          >
            <Col xs={12}>
              <div className="content" ref={contentRef}>
                <div className="textbox-marker-container">
                  <div className="image">
                    <figure>
                      {props.content.cfgImg ? (
                        <PbContentImage
                          img={props.content.cfgImg}
                          cmsPosition={props.position}
                          cmsFieldImage={"img"}
                          maxWidth={`${Math.round(
                            props.content.cfgImageWidth
                          )}px`}
                          maxHeight={`${props.content.cfgImageHeight}px`}
                          height={"auto"}
                          width="100%"
                          isMobile={props.isMobile}
                          disableEditView
                          className="mx-auto"
                          imageRef={imageRef}
                          widthLimitSettings={Math.round(
                            props.content.cfgImageWidth
                          )}
                        />
                      ) : (
                        <div style={{ height: "300px" }}></div>
                      )}
                    </figure>
                  </div>
                  {props.content.content.map(
                    (item: CEImageWithMarkersItem, index: number) => {
                      const { id: iconId } = getIcon(
                        item.cfgStrMarkerStyle,
                        props.ceSettings?.marker
                      );
                      const showMarkerNumber =
                        editView && iconId ? true : false;
                      const backgroundColor = overlayItemBackgroundColor(
                        item.cfgStrBackgroundColor
                      );
                      const markerColor = overlayItemMarkerColor(
                        item.cfgStrMarkerColor
                      );
                      const textboxBoundaries = calcTextboxBoundaries(
                        index,
                        item
                      );
                      const markerPosition = calcMarkerPosition(item, index);
                      const textboxPos = {
                        x: textboxBoundaries.tempPositionX || item.position.x,
                        y: item.position.y,
                      };
                      const showTooltip =
                        editView && pushedTextboxIds.includes(index);

                      return (
                        <React.Fragment key={"draggable-" + index}>
                          <Draggable
                            position={markerPosition}
                            onStart={() => {
                              if (positionEditMode && editView) {
                                const scrollbarWidth =
                                  window.innerWidth -
                                  document.documentElement.clientWidth;
                                document.body.style.paddingRight = `${scrollbarWidth}px`;
                                document.body.style.overflow = "hidden";
                              } else {
                                return false;
                              }
                            }}
                            onStop={(e, ui) => {
                              if (positionEditMode && editView) {
                                document.body.style.removeProperty("overflow");
                                document.body.style.paddingRight = "0px";
                                updateMarkerPosInRedux(ui, index);
                              } else {
                                return false;
                              }
                            }}
                            bounds="parent"
                            nodeRef={markersRef.current[index]}
                          >
                            <div
                              className={clsx(
                                "marker-position-wrapper-free",
                                editView && "edit-mode",
                                cfgShowMarkersOnAllSizes && "visible"
                              )}
                              ref={markersRef.current[index]}
                            >
                              <AnimationWrapper
                                animationIn={item.cfgAnimationIn}
                                animationOut={item.cfgAnimationOut}
                              >
                                <button
                                  className="marker-button"
                                  aria-label={tCms(
                                    "imgwithmarkers-open-text-overlay"
                                  )}
                                  tabIndex={!editView ? 0 : -1}
                                  onClick={() => {
                                    return cfgShowMarkersOnAllSizes &&
                                      !editView &&
                                      item.text
                                      ? toggleDialog(item)
                                      : null;
                                  }}
                                  onTouchEnd={() => {
                                    return cfgShowMarkersOnAllSizes && !editView
                                      ? toggleDialog(item)
                                      : null;
                                  }}
                                >
                                  <div className="marker-container">
                                    <div
                                      className="marker"
                                      style={{
                                        backgroundColor: markerColor || "blue",
                                        boxShadow: `0 0 1px 2px ${markerColor}`,
                                      }}
                                    >
                                      {iconId ? (
                                        <div
                                          className={`str-icon-${iconId}`}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            backgroundSize: "cover",
                                          }}
                                        />
                                      ) : (
                                        <span>{index + 1}</span>
                                      )}
                                      {editView && showMarkerNumber && (
                                        <div className="marker-number">
                                          {index + 1}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </button>
                              </AnimationWrapper>
                            </div>
                          </Draggable>
                          <Draggable
                            position={textboxPos}
                            onStop={(e, ui) => {
                              onRepositionStop(ui, index);
                            }}
                            bounds="parent"
                            cancel=".pb-richtext, .react-resizable-handle, .temp-pos, .right-controls"
                            nodeRef={textItemsRef.current[index]}
                          >
                            <Resizable
                              height={textboxBoundaries.height}
                              width={textboxBoundaries.width}
                              maxConstraints={[
                                textboxBoundaries.maxWidth,
                                textboxBoundaries.maxHeight,
                              ]}
                              onResize={(
                                e: SyntheticEvent,
                                values: ResizeCallbackData
                              ) => {
                                // lags with redux logging
                                if (positionEditMode && editView) {
                                  dispatch(
                                    updateAttributeAction({
                                      attributePath: `draftPage.content[${props.position}].content[${index}].width`,
                                      value: Math.min(
                                        values.size.width,
                                        textboxBoundaries.maxWidth
                                      ),
                                    })
                                  );
                                  dispatch(
                                    updateAttributeAction({
                                      attributePath: `draftPage.content[${props.position}].content[${index}].height`,
                                      value: Math.min(
                                        values.size.height,
                                        textboxBoundaries.maxHeight
                                      ),
                                    })
                                  );
                                }
                              }}
                            >
                              <div
                                ref={textItemsRef.current[index]}
                                className={clsx(
                                  positionEditMode && editView && "edit-mode",
                                  !editView &&
                                    cfgShowMarkersOnAllSizes &&
                                    "d-none",
                                  "text-box"
                                )}
                                style={{
                                  width: textboxBoundaries.width + "px",
                                  height: textboxBoundaries.height + "px",
                                }}
                              >
                                <div
                                  className="drag-zone"
                                  onClick={() => makeTextboxVisible(index)}
                                >
                                  <span>{index + 1}</span>
                                </div>
                                {showTooltip && (
                                  <div className="temp-pos">
                                    <CmsTooltip
                                      iconHeight={20}
                                      iconWidth={20}
                                      iconName="circle-question-light"
                                      title={tCms("imgWithMarkers-textboxInfo")}
                                    />
                                  </div>
                                )}
                                <AnimationWrapper
                                  animationIn={item.cfgAnimationIn}
                                  animationOut={item.cfgAnimationOut}
                                >
                                  <div
                                    className={clsx(
                                      "text-content",
                                      editView && "editview"
                                    )}
                                    style={{
                                      backgroundColor:
                                        backgroundColor || "white",
                                    }}
                                  >
                                    <CmsGeneralConfigFrame
                                      frameOnHover={true}
                                      hideAddCEButton={true}
                                      moveDirection={"horizontal"}
                                      onDelete={() =>
                                        handleOnMarkerDelete(index)
                                      }
                                      position={index}
                                      arrayLength={props.content.content.length}
                                      openConfigModal={() => {
                                        dispatch(
                                          showNestedConfigModal({
                                            nestedPosition: index,
                                            position: props.position,
                                            contentElementSetting:
                                              ceSettingById(
                                                valueFromStoreSetting(
                                                  props.content
                                                    .cfgStrContentElementSetting
                                                )
                                              ),
                                          })
                                        );
                                      }}
                                    >
                                      <PbContentRichText
                                        className={clsx(richTextClassName)}
                                        content={item.text}
                                        ceSettings={getNestedCeSettingsData(
                                          props.ceSettings?.richtext!
                                        )}
                                        position={props.position}
                                        toolbarBelow
                                        cmsFieldContent={`content[${index}].text`}
                                      />
                                    </CmsGeneralConfigFrame>
                                  </div>
                                </AnimationWrapper>
                              </div>
                            </Resizable>
                          </Draggable>
                        </React.Fragment>
                      );
                    }
                  )}
                </div>
              </div>
            </Col>
          </Row>
          {editView && (
            <div className="edit-buttons-wrapper">
              <div className="add-text-box">
                <PbButton
                  style={{
                    margin: "0 0 0 10px",
                    fontWeight: "bold",
                    height: "50px",
                  }}
                  startIcon={
                    <PbIcon
                      name="plus-light"
                      width={20}
                      height={20}
                      className="svg-fill-white"
                    />
                  }
                  onClick={() =>
                    dispatch(
                      cmsAddNestedContentElementThunk(
                        nestedElementAttributes,
                        props.position,
                        "content",
                        props.content.content.length + 1
                      )
                    )
                  }
                >
                  {tCms("add")}
                </PbButton>
              </div>
              <div className="enter-position-editmode-box">
                <PbButton
                  style={{
                    margin: "0 0 0 10px",
                    fontWeight: "bold",
                    height: "50px",
                  }}
                  onlyVisibleInEditView
                  onClick={() => setPositionEditMode((prevState) => !prevState)}
                >
                  {positionEditMode
                    ? tCms("imgWithMarkers-togglePositionEditMode-active")
                    : tCms("imgWithMarkers-togglePositionEditMode-disabled")}
                </PbButton>
              </div>
              <div>
                <PbButton
                  onlyVisibleInEditView
                  onClick={toggleTextboxesVisibility}
                  style={{
                    margin: "0 0 0 10px",
                    fontWeight: "bold",
                    height: "50px",
                  }}
                >
                  {tCms("imgWithMarkers-hideAllTextboxes")}
                </PbButton>
              </div>
              <div>
                <PbButton
                  onlyVisibleInEditView
                  onClick={() =>
                    showResetPositionConfirmModal({
                      title: tCms("imgWithMarkers-resetPositionEditMode"),
                      content: tCms(
                        "imgWithMarkers-resetPositionEditModeConfirm"
                      ),
                      acceptBtnText: tCms("resetting"),
                      denyBtnText: tCms("cancel"),
                      xIsDeny: true,
                      icon: "triangle-exclamation-light",
                    })
                  }
                  style={{
                    margin: "0 0 0 10px",
                    fontWeight: "bold",
                    height: "50px",
                  }}
                  color="error"
                >
                  {tCms("imgWithMarkers-resetPositionEditMode")}
                </PbButton>
              </div>
            </div>
          )}
        </div>
        <PbGenericModal
          hideHeader
          className={`${IMAGE_WITH_MARKERS_CE_NAME}-text-box ${IMAGE_WITH_MARKERS_SCOPED_CLASS}-modal`}
          open={dialogOpen ? true : false}
          title={tCms("addNewElement")}
          onBackdropClick={() => toggleDialog(undefined)}
          muiDialogProps={{
            fullWidth: true,
            maxWidth: "lg",
            PaperProps: {
              style: {
                minWidth: "150px",
                width: "unset",
                backgroundColor:
                  overlayItemBackgroundColor(
                    dialogContent?.cfgStrBackgroundColor
                  ) ?? "var(--pb-white)",
              },
            },
          }}
          muiDialogContentSxProps={{ paddingRight: "50px !important" }}
        >
          <>
            <button
              className="close-text-box"
              onClick={() => toggleDialog(undefined)}
            >
              <PbIcon
                name="xmark-light"
                width={25}
                height={25}
                className="svg-fill-black"
              />
            </button>
            <PbContentRichText
              className={clsx(richTextClassName)}
              content={dialogContent?.text ?? ""}
              ceSettings={getNestedCeSettingsData(props.ceSettings?.richtext!)}
              position={props.position}
              disableEditView
            />
          </>
        </PbGenericModal>
      </ContentElementWrapper>
      <ImageWithMarkersContentElementStyleGlobal {...props} />
      <ImageWithMarkersContentElementStyleScoped
        scopedSelector={IMAGE_WITH_MARKERS_SCOPED_CLASS}
        {...props}
      />
    </>
  );
});
